import { Injectable } from '@angular/core';
import { CrmTableProvider, CrmTableRowItem } from 'common-module/table';
import { Subject } from 'rxjs';

@Injectable()
export abstract class TableSelectProvider<
  Row extends CrmTableRowItem,
  Select = Row,
> extends CrmTableProvider<Row> {
  readonly select$ = new Subject<Select>();
}
